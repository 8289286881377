import { render, staticRenderFns } from "./MarkOffer.vue?vue&type=template&id=5b63acbe&scoped=true"
import script from "./MarkOffer.vue?vue&type=script&lang=js"
export * from "./MarkOffer.vue?vue&type=script&lang=js"
import style0 from "./MarkOffer.vue?vue&type=style&index=0&id=5b63acbe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b63acbe",
  null
  
)

export default component.exports