<template>
  <div class="mark-offer" :class="text ? 'mark-offer-new' : ''" v-if="text">{{ text }}</div>
</template>

<script>
export default {
  props: ["text", "post"],
};
</script>

<style lang="scss" scoped>
.mark-offer {
  position: absolute;
  background: $primary_color;
  color: #fff;
  top: $mpadding * 1.4;
  right: -$mpadding * 3.7;
  font-weight: bold;
  line-height: 20px;
  transform: rotate(45deg);
  width: 180px;
  box-shadow: 0 3px 10px -5px black;
  text-align: center;
  padding: $mpadding/2;
  z-index: 1;
  &-new {
    line-height: 6px;
    max-height: 20px;
    font-size: 13px;
  }
}
</style>
